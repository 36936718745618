import api from "@/api";
import BreadCrumbs from "@/components/BreadCrumbs";
import { DefaultSetting, MetaRow } from "@/models/type";
import { parseTime, sharer } from "@/utils";
import { setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import { RouteLocationMatched } from "vue-router";

import './index.scss'

export default class SubjectDetail extends Vue {
  public detailId = 0
  public chapterList: Array<SmsSubjectChapterDtos> = []
  public smsSubjectObj: SmsSubjectAndChapterDto = {}
  public tutorObj: SmsSubjectTutor = {}

  metaList: Array<MetaRow> = []
  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })

  getDetail(): void {
    api.getSubjectById(this.detailId).then(res => {
      this.chapterList = res.data.chapter
      this.smsSubjectObj = res.data.smsSubject
      this.tutorObj = res.data.tutor
      this.getMeta()
    })
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      {property: 'og:title', content: this.smsSubjectObj.title as string},
      {property: 'og:description', content: this.smsSubjectObj.introduce as string},
      {property: 'og:image', content: this.smsSubjectObj.coverImg as string}
    )
  }

  mainDom(): JSX.Element {
    return <div class="subject-detail-main">
      <el-row>
        <el-col xs={24} sm={24} md={18} lg={18} xl={18} class="subject-detail-main-left">
          <img src={this.smsSubjectObj.mainImg} alt={this.smsSubjectObj.title} />
        </el-col>
        <el-col xs={24} sm={24} md={6} lg={6} xl={6} class="subject-detail-main-right">
          <div class="main-title">{ this.smsSubjectObj.title }</div>
          <div class="main-length">课程共计{ this.chapterList.length }节</div>
          <ul class="main-chapter">
            {this.chapterList.map(item => <li>
              <span class="main-chapter-title">{item.title}</span>
              <span class="main-chapter-time">{parseTime(new Date(item.time as string).getTime())}</span>
            </li>)}
          </ul>
        </el-col>
      </el-row>
      <el-row class="subject-detail-main-bottom">
        <el-col xs={24} sm={24} md={18} lg={18} xl={18} class="subject-detail-main-bottom-left">
          <div class="label">{ this.smsSubjectObj.title }</div>
          {
            this.smsSubjectObj.title
              && <div class="fun">
                  <share-network
                    network="facebook"
                    url={window.location.href}
                    title={this.smsSubjectObj.title}
                    description={this.smsSubjectObj.introduce}
                  >
                    <div class="facebook" />
                  </share-network>
                  <div class="wechat" onClick={() => sharer(2)} />
                  <share-network
                      network="whatsapp"
                      url={window.location.href}
                      title={this.smsSubjectObj.title}
                      description={this.smsSubjectObj.introduce}
                    >
                    <div class="phone" />
                  </share-network>
              </div>
          }
        </el-col>
        <el-col xs={24} sm={24} md={6} lg={6} xl={6} class="subject-detail-main-bottom-right">
          <div onClick={this.signup}>
            <i class="el-icon-plus"/>
            <span>立即报名</span>
          </div>
        </el-col>
      </el-row>
    </div>
  }

  contentDom(): JSX.Element {
    return <el-row class="subject-detail-content" gutter={30}>
      <el-col xs={24} sm={24} md={18} lg={18} xl={18} class="subject-detail-content-left">
        <div class="content-title">
          <span>课程概述</span>
        </div>
        <div class="content-detail">
          <p>{ this.smsSubjectObj.introduce }</p>
          {this.smsSubjectObj.otherImg?.split(',').map(item => <img src={ item } alt=''/>)}
        </div>
      </el-col>
      <el-col xs={24} sm={24} md={6} lg={6} xl={6} class="subject-detail-content-right">
        <div class="content-tutor">
          <div class="content-tutor-info">
            <img src={this.tutorObj.imgUrl} alt={this.tutorObj.title} />
            <div class="content-tutor-info-right">
              <div>{this.tutorObj.title}</div>
              <div>{this.tutorObj.identity}</div>
            </div>
          </div>
          <div class="content-tutor-introduce">
            <div class="content-tutor-title">
              <span>介绍</span>
            </div>
            <p>{this.tutorObj.introduce}</p>
          </div>
          <div class="content-tutor-title">
            <span>联系方式</span>
          </div>
          <div class="content-tutor-link"><i class="el-icon-mobile-phone"/><span>{this.tutorObj.phone}</span></div>
          <div class="content-tutor-link"><i class="el-icon-message"/><span>{this.tutorObj.email}</span></div>
        </div>
      </el-col>
    </el-row>
  }

  /**
   * @description 报名
   * @author Jinx
   * @date 2021-10-17 18:24:46
   * @memberof Subjectdetail
   */
  signup(): void {
    const arr = this.$route.matched
    const obj = arr[arr.length - 1] as RouteLocationMatched
    obj.path = obj.path.replace(':id', this.$route.params.id as string)
    this.$router.push({
      name: 'SubjectSignup',
      params: obj as any,
      query: {
        id: this.$route.params.id
      }
    })
  }

  created(): void {
    const { id } = this.$route.params
    this.detailId = +id
  }

  render(): JSX.Element {
    return <div class="subject-detail">
      <BreadCrumbs />
      {this.mainDom()}
      {this.contentDom()}
    </div>
  }

  mounted(): void {
    this.getDetail()
  }
}

/**
 * @description 章节
 * @author Jinx
 * @date 2021-08-20 17:12:49
 * @export
 * @interface smsSubjectChapterDtos
 * @extends {DefaultSetting}
 */
 export interface SmsSubjectChapterDtos extends DefaultSetting {
  integral?: string // 积分
  subjectId: number | string // 课程id
  time?: string // 时间
  title?: string // 名称
}

/**
 * @description 课程
 * @author Jinx
 * @date 2021-08-20 17:13:17
 * @export
 * @interface SmsSubjectAndChapterDto
 * @extends {DefaultSetting}
 */
export interface SmsSubjectAndChapterDto extends DefaultSetting {
  coverImg?: string // 封面图片地址
  identity?: string // 报名身份
  introduce?: string // 课程介绍
  mainImg?: string // 主图地址
  otherImg?: string // 其他图片地址
  peopleCount?: number // 已报名人数
  peopleNumber?: number // 报名人数
  price?: number | string // 课程价格
  signUpEndTime?: Date // 报名结束时间
  signUpStartTime?: Date // 报名开始时间
  smsSubjectChapterDtos?: Array<SmsSubjectChapterDtos> // 章节
  subjectEndTime?: Date // 课程结束时间
  subjectStartTime?: Date // 课程开始时间
  monthId?: number | string | Array<string | number> // 系列课名称id
  title?: string // 系列课名称
  tutorId?: string | number // 导师
  typeId?: number // 课程类型
  signUpTime?: Array<Date> // 前端时间
  subjectTime?: Array<Date> // 前端时间
}


/**
 * @description 导师
 * @author Jinx
 * @date 2021-08-20 17:48:49
 * @export
 * @interface SmsSubjectTutor
 * @extends {DefaultSetting}
 */
 export interface SmsSubjectTutor extends DefaultSetting {
  email?: string // 邮箱
  identity?: string // 身份
  imgUrl?: string // 图片
  introduce?: string // 介绍
  phone?: string // 手机号
  status?: number // 是否展示
  subjectType?: number | string // 课程类型
  title?: string // 姓名
}
